import React from 'react'
import AnimatedLink from './animatedLink';
// @ts-ignore
import ChevronRightGrey from '../../svg/chevron-right-grey.svg'

interface props {
  pageTitle: string
  transitionStatus: string
  parents: {
    treeParent: any
    slug: string
    pageTitle: string
  }
}

export default function Breadcrumbs({ pageTitle, transitionStatus, parents }:props) {
  let convertedParents = [];

  const pushToConvertedParents = (slug, pageTitle) => {
    convertedParents.push({
      slug: slug,
      pageTitle: pageTitle,
    })
  }

  const treeParentRecursive = (parent) => {
    if (parent.treeParent) {
      if (parent.treeParent.slug == 'home') return
      pushToConvertedParents(parent.treeParent.slug, parent.treeParent.pageTitle);
      treeParentRecursive(parent.treeParent);
    } else if (parent.parentHub) {
      if (parent.parentHub.slug == 'home') return
      pushToConvertedParents(parent.parentHub.slug, parent.parentHub.pageTitle);
      treeParentRecursive(parent.parentHub);
    } else if (parent.parentPage) {
      if (parent.parentPage.slug == 'home') return
      pushToConvertedParents(parent.parentPage.slug, parent.parentPage.pageTitle);
      treeParentRecursive(parent.parentPage);
    }
  }

  if (parents && parents.slug !== 'home') {
    pushToConvertedParents(
      parents.slug, 
      parents.pageTitle
    ) 
    treeParentRecursive(parents)
  }

  convertedParents.reverse();
  
  function getUrl(thisParent, index) {
    if (thisParent.slug == 'home') return '';
    
    if (index > 0) {
      let slug = '/';
      for (let p = 0; p < index; p++) {
        const parent = convertedParents[p].slug;
        slug = slug + `${parent}/`;
      }
      
      return slug + thisParent.slug;
    } else {      
      return `/${thisParent.slug}`; 
    }
  }

  return (
    <div className={`breadcrumbs ${transitionStatus}`}>
      <div className="outer-container">
        <div className="breadcrumbs__inner inner-container">
          {/* <HouseIcon/> */}
          <AnimatedLink to={`/`} className={``} delay={0}>
            Home
          </AnimatedLink>
          {parents !== null && convertedParents.length > 0 ? 
            convertedParents.map((parent, index) => {
              return (
                <span key={'parent-' + index}>
                  <ChevronRightGrey className="breadcrumbs__divider" />
                  <AnimatedLink to={getUrl(parent, index)} className={``}>
                    {parent.pageTitle} 
                  </AnimatedLink>
                </span>
              )
            })
          :''}
          <ChevronRightGrey className="breadcrumbs__divider" />
          <span className="breadcrumbs__current">{pageTitle}</span>
        </div>
      </div>
    </div>
  )
}